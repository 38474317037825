import router from "@/router";
import App from "@/App.vue";
import {createApp} from "vue";
import {MotionPlugin} from "@vueuse/motion";

import '@/assets/scss/styles.scss';

createApp(App)
    .use(router)
    .use(MotionPlugin)
    .mount("#app");