<script>
export default {
  name: 'App',
}
</script>

<template>
  <router-view/>
</template>

<style>
.highlight-title {
  text-shadow: 0 0 25px rgba(125, 205, 255, 0.5);

  line-height: 120%;

  text-transform: uppercase;

  font-size: 48px;
  font-family: "Cinzel SemiBold", serif;

  color: #B8E8FF;
}

@media (max-width: 768px) {
  .highlight-title {
    font-size: 32px;
  }
}
</style>
